var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseMessage" },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: "参算方式" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.form.calcType,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "calcType", $$v)
                },
                expression: "form.calcType",
              },
            },
            [
              _c("a-radio", { attrs: { value: "1" } }, [_vm._v("加项")]),
              _c("a-radio", { attrs: { value: "2" } }, [_vm._v("减项")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: "是否计税" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.form.taxable,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "taxable", $$v)
                },
                expression: "form.taxable",
              },
            },
            [
              _c("a-radio", { attrs: { value: 1 } }, [_vm._v("是")]),
              _c("a-radio", { attrs: { value: 0 } }, [_vm._v("否")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }